import React from "react";
import ReactToPrint from "react-to-print";
import FormDataLinks from "./FormDataLinks";

// class Content extends React.Component {
// 	render() {}
// }

class ModalExample extends React.PureComponent {
	render() {
		let closeModal = () => this.setState({ open: false });

		let saveAndClose = () => {
			// api.saveData().then(() => this.setState({ open: false }));
		};

		console.log("form data Print Modal", this.props.fileData);

		let rows = [];

		FormDataLinks.FormDataLinks.forEach((link, ind) => {
			if (this.props.formData && this.props.formData[link.fieldName])
				rows.push(
					<div key={`${link.fieldName}-print`}>
						<span style={{ fontWeight: 800 }}>
							{this.props.formData && this.props.formData[link.fieldName] ? link.questionText : ""}
						</span>
						<br />
						{this.props.formData && this.props.formData[link.fieldName]
							? this.props.formData[link.fieldName]
							: ""}
					</div>
				);
		});

		const fileData = this.props.fileData;

		const objKeys = Object.keys(fileData);

		rows.push(
			<div key={`$fileNames-print`}>
				<br />
				<span style={{ fontWeight: 800 }}>Files Uploaded</span>
			</div>
		);

		for (var i = 0; i < objKeys.length; i++) {
			for (var f = 0; f < fileData[objKeys[i]].files.length; f++) {
				console.log(fileData[objKeys[i]].files[f].name);
				const fileName = fileData[objKeys[i]].files[f].name;

				rows.push(<div key={`${fileName}-print`}>{fileName}</div>);
			}
		}

		{
			/* <tr className={"dataRow"}>
						<td className={"dataCol"}>
							<span style={{ fontWeight: 800 }}>
								{this.props.formData && this.props.formData[link.fieldName]
									? link.questionText
									: ""}
							</span>
						</td>
						<td className={"dataCol"}>
							{this.props.formData && this.props.formData[link.fieldName]
								? this.props.formData[link.fieldName]
								: ""}
						</td>
					</tr> */
		}

		let content = () => (
			<div className={"modal"}>
				<div style={{ float: "right", right: 0, position: "fixed", margin: 25 }}>
					<button onClick={() => this.props.setOpen(false)}>CLOSE</button>
				</div>
				<ReactToPrint
					content={() => this.componentRef}
					trigger={() => {
						return <button>PRINT</button>;
					}}
				/>
				<div
					data={this.props.data}
					ref={(el) => (this.componentRef = el)}
					style={{ padding: 50, textAlign: "left" }}
				>
					<div style={{ fontWeight: 600 }}>
						{this.props.formData.firstName ? this.props.formData.firstName : "TEST"}{" "}
						{this.props.formData.lastName ? this.props.formData.lastName : "ENTRY"}
					</div>
					<div>
						{this.props.formData.submissionID ? this.props.formData.submissionID : "123-4567-8910"}
					</div>
					<br />
					<div>
						<b>Date of Submission</b>
						<br />
						{new Date().toLocaleString()}
					</div>
					<br />

					{rows}
				</div>
			</div>
		);

		return this.props.open ? content() : <div></div>;
	}
}

export default ModalExample;
