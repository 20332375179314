const FormDataLinks = [
	{ fieldName: "liveInSF", questionText: "Do you currently live in San Francisco?" },
	{
		fieldName: "howManyInHousehold",
		questionText: "How many people are in your household? (include yourself)",
	},
	{
		fieldName: "householdMonthlyGross",
		questionText:
			"What is your household’s current monthly income?: Please include any income	from employment (formal or informal) and cash benefits. If your income varies by month, please provide your income from the last 30 days. (Please note, if you have savings/cash resources that amount to three times your monthly rent, you will not be eligible for assistance from this program.)",
	},
	{
		fieldName: "applyingNewUnit",
		questionText:
			"Are you applying for assistance with past-due rent OR assistance with moving into a new unit (security deposit, etc.)?",
	},
	{
		fieldName: "recentHardship",
		questionText:
			"What best describes your recent financial hardship (within the last 12 months)?*",
	},
	{ fieldName: "recentHardshipOther", questionText: "Describe your recent hardship" },
	{
		fieldName: "bestDescribeIncExp",
		questionText:
			"Which of the following best describe your increase in expenses? Choose all that apply.",
	},
	{ fieldName: "bestDescribeIncExpOther", questionText: "Describe your increase in expenses" },
	{
		fieldName: "bestDescribeIncExpDetail",
		questionText: "Is this increased expense one-time or ongoing?",
	},
	{
		fieldName: "householdPriorMonthlyGross",
		questionText:
			"What was your MONTHLY household income PRIOR to your household’s loss of income?",
	},
	{ fieldName: "howDidYouHear", questionText: `How did you hear about us?` },
	{
		fieldName: "appliedForRent",
		questionText: `							Have you applied for and/or received rent or financial assistance since March 2020
							from any other program or resources?* This may include previous assistance from the
							San Francisco Emergency Rental Assistance Program, Season of Sharing, or CA COVID-19
							Rent Relief Program. This will NOT disqualify you for additional financial help, but
							receiving help from two or more programs for the same months of rent will not be
							permitted.`,
	},
	{
		fieldName: "receivedAssistanceFrom",
		questionText: `If yes, from where did you receive assistance?`,
	},
	{
		fieldName: "amountOfAssistance0",
		questionText: `What was the amount of assistance you received?`,
	},
	{
		fieldName: "whatMonthsCoveredByAssistance",
		questionText: `What months were covered by the assistance you received?`,
	},
	{ fieldName: "firstName", questionText: `First Name` },
	{ fieldName: "lastName", questionText: `lastName` },
	{ fieldName: "dob", questionText: `Date of Birth (MM/DD/YYYY)` },
	{ fieldName: "sexualOrientation", questionText: `Sexual Orientation` },
	{ fieldName: "genderIdentity", questionText: `Gender Identity` },
	{ fieldName: "preferredPronoun", questionText: `Preferred Pronouns` },
	{ fieldName: "phone", questionText: `Primary Phone #` },
	{ fieldName: "secPhone", questionText: `Secondary Phone #` },
	{ fieldName: "email", questionText: `E-mail Address` },
	{ fieldName: "needInterpretation", questionText: `Do you need interpretation services?` },
	{ fieldName: "needInterpretationLang", questionText: `If Yes, what language?` },
	{ fieldName: "bestTimeToContactString", questionText: `Best time to contact` },
	{
		fieldName: "ethnicity",
		questionText: `Which best describes your race/ethnicity? (Mark ALL check boxes that apply)`,
	},
	{ fieldName: "address1", questionText: `Current Address` },
	{ fieldName: "address2", questionText: `Unit or Apartment #` },
	{ fieldName: "city", questionText: `City` },
	{ fieldName: "zip", questionText: `Zip / Postal code` },
	{ fieldName: "typeOfHousing", questionText: `Where are you currently living?` },
	{
		fieldName: "rentalSubsidyLowIncome",
		questionText: `Do any of the above apply to your CURRENT housing situation?`,
	},
	{
		fieldName: "typeOfSubsidy",
		questionText: `Please specify the type of housing program you are in.`,
	},
	{ fieldName: "liveInRentControlledUnit", questionText: `Do you live in a rent-controlled unit?` },
	{ fieldName: "livedFor5YearsMore", questionText: `Have you lived there for 5 years or more?` },
	{
		fieldName: "namesInHousehold",
		questionText: `Please provide the first and last names of all members of your household age 18 or
							older.`,
	},
	{
		fieldName: "majorChangeHousehold",
		questionText: `Have you had a major change of who's a part of your household (e.g., birth, divorce,
							death ) in past 12 months?`,
	},
	{ fieldName: "anyonePregnant", questionText: `Is anyone in your household currently pregnant?` },
	{
		fieldName: "howManyChildrenInHouseholdUnder2",
		questionText: `How many children in the household are 0-2 years old?`,
	},
	{
		fieldName: "howManyChildrenInHousehold",
		questionText: `How many children in the household between the ages 3-17 years old?`,
	},
	{
		fieldName: "howMany18to24InHousehold",
		questionText: `How many people are between the ages of 18-24 years?`,
	},
	{
		fieldName: "howMany25to54InHousehold",
		questionText: `How many people are between the ages of 25-61 years?`,
	},
	{ fieldName: "howManySeniors", questionText: `How many people are 62 years or older?` },
	{
		fieldName: "headOfHousehold25",
		questionText: `Are you (the head of household) under the age of 25?`,
	},
	{
		fieldName: "anyoneInHouseholdVeteran",
		questionText: `Are you or any other adult in the household a veteran?`,
	},
	{ fieldName: "employed", questionText: `Are you currently employed?` },
	{ fieldName: "employment", questionText: `If employed, what is your employment type?` },
	{
		fieldName: "interestedEmployment",
		questionText: `Are you interested in increasing your income through employment or education?`,
	},
	{
		fieldName: "backRentOccupy",
		questionText: `Are you applying for back rent on a unit you currently occupy?`,
	},
	{
		fieldName: "utilDebtNoLongerOccupy",
		questionText: `Do you have rental debt from a unit you no longer occupy?`,
	},
	{ fieldName: "currentMonthlyRent", questionText: `What is (or was) your monthly rent?` },
	{ fieldName: "backRentAmount", questionText: `How much total rent do you currently owe?` },
	{
		fieldName: "backRentMonths",
		questionText: `Number of months behind on rent (whole numbers only)?`,
	},
	{
		fieldName: "assistanceTimePeriod",
		questionText: `What time period (months and years) do you need rental assistance for?`,
	},
	{
		fieldName: "incurDebt",
		questionText: `Since March 2020 have you taken on any type of debt in order to pay rent? (Reimbursement for rent paid is not available through SF ERAP.)`,
	},
	{ fieldName: "incurDebtType", questionText: `What type of debt?` },
	{
		fieldName: "howMuchDebt",
		questionText: `How much debt have you taken on to pay rent or utilities?`,
	},
	{
		fieldName: "incurDebtMonthsYears",
		questionText: `Please list months/year in which debt was incurred and amount per month.`,
	},
	{
		fieldName: "llName",
		questionText: `First and Last Name of Landlord, Master Tenant, Person Who Charges You Rent, or Management Company Contact`,
	},
	{ fieldName: "landlordaddress1", questionText: `Landlord Address line 1` },
	{ fieldName: "landlordaddress2", questionText: `Landlord Address line 2` },
	{ fieldName: "landlordcity", questionText: `Landlord City` },
	{ fieldName: "landlordstate", questionText: `Landlord State/Province/Region` },
	{ fieldName: "landlordzip", questionText: `Landlord Zip / Postal code` },
	{ fieldName: "landlordPhone", questionText: `Landlord Primary Phone #` },
	{ fieldName: "landlordEmail", questionText: `Landlord E-mail` },
	{
		fieldName: "moveInAssistance",
		questionText: `Are you applying for move-in assistance (deposit, first month’s rent, and/or last
							month’s rent) for a unit you have already identified, been approved for, and intend to
							lease?`,
	},
	{ fieldName: "moveInAddress1", questionText: `Move-in Address Address line 1` },
	{ fieldName: "moveInAddress2", questionText: `Move-in Address line 2` },
	{ fieldName: "moveInCity", questionText: `Move-in City ` },
	{ fieldName: "moveInState", questionText: `Move-in State ` },
	{ fieldName: "moveInZip", questionText: `Move-in Zip` },
	{
		fieldName: "unitMovingInto",
		questionText: `Do any of the above apply to the unit you are moving into?`,
	},
	{
		fieldName: "typeOfSubsidyMovingInto",
		questionText: `Please specify the type of housing program you are in.`,
	},
	{ fieldName: "moveInDate", questionText: `"Move-in date` },
	{ fieldName: "Security_x0020_Deposit", questionText: `Security deposit amount` },
	{ fieldName: "First_x0020_Month_x0020_Rent", questionText: `First month's rent amount` },
	{ fieldName: "Last_x0020_Month_x0020_Rent", questionText: `Last month's rent amount` },
	{ fieldName: "datePaymentDue", questionText: `Date payment is due` },
	{
		fieldName: "moveInLandlordName",
		questionText: `First and Last Name of Landlord, Master Tenant, Person Who Charges You Rent, or Management Company Contact`,
	},
	{ fieldName: "moveInLandlordAddress1", questionText: `Move-in Landlord Address Line 1` },
	{ fieldName: "moveInLandlordAddress2", questionText: `Move-in Landlord Address Line 2` },
	{ fieldName: "moveInLandlordCity", questionText: `Move-in Landlord City` },
	{ fieldName: "moveInLandlordState", questionText: `Move-in Landlord State` },
	{ fieldName: "moveInLandlordZip", questionText: `Move-in Landlord Zip` },
	{ fieldName: "moveInLandlordPhone", questionText: `Move-in Landlord Phone` },
	{ fieldName: "moveInLandlordEmail", questionText: `Move-in Landlord E-mail` },
	{
		fieldName: "beingPressured",
		questionText: `Are you being pressured to move out of your housing by your landlord, master tenant,
							apartment manager, roommate or anyone else?`,
	},
	{
		fieldName: "pastDueRentNotice",
		questionText: `Has your landlord given you something in writing (a notice) that you have past-due
							rent?`,
	},
	{
		fieldName: "receivedCourtPapers",
		questionText: `Have you received court papers (an ‘Unlawful Detainer’) to move out of your housing?
							If yes, please contact the Eviction Defense Collaborative immediately at (415)
							659-9184 or legal@evictiondefense.org and continue with this application.`,
	},
	{
		fieldName: "livingWithoutLease",
		questionText: `Are you currently living in someone else’s home or apartment where you do not have a
							lease?`,
	},
	{
		fieldName: "expHomelessness",
		questionText: `Are you currently or have you ever been homeless (for example, you slept in a shelter,
							outside, in your car, or another place not meant for people to sleep)?`,
	},
	{
		fieldName: "homelessLast2Years",
		questionText: `Did your experience of homelessness occur in the last two years?`,
	},
	{
		fieldName: "firstTimeHomeless",
		questionText: `Was this your first time experiencing homelessness?`,
	},
	{
		fieldName: "landlordFiledPapers",
		questionText: `As an adult, have you lost your home through eviction?`,
	},
	{
		fieldName: "policeInvolvement",
		questionText: `Have you or any adult in your household been arrested or spent any time in jail or
							prison in the last two years?`,
	},
	{
		fieldName: "anyoneInHouseholdDisabled",
		questionText: `Do you or anyone in your household have a disabling condition which impacts your
							ability to secure/maintain housing?`,
	},
	{
		fieldName: "dischargeHospitalMHSub",
		questionText: `Have you or any adult in your household been discharged from a hospital, mental health
							facility or substance abuse treatment facility in the last year?`,
	},
	{
		fieldName: "ineligibleAssistance",
		questionText: `Do you or anyone in your household have or need an ITIN (Individual Taxpayer Identification Number)?`,
	},
	{
		fieldName: "specificOrganization",
		questionText: `If you prefer to work with a specific organization to determine whether you qualify
							for assistance, please select one from the list below.`,
	},
	{ fieldName: "submittedBy", questionText: `Form Submitted By` },
	{
		fieldName: "electronicSignature",
		questionText: `Type your full name for electronic signature submission`,
	},
	{ fieldName: "repName", questionText: `Representative First & Last Name` },
	{ fieldName: "repEmail", questionText: `Representative's Email` },
	{ fieldName: "repPhone", questionText: `Representative's Phone` },
	{ fieldName: "repRelationship", questionText: `Representative’s Relationship to You` },
	{ fieldName: "repRelationshipOther", questionText: `Describe Relationship` },
];

module.exports.FormDataLinks = FormDataLinks;
