import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Slider from "@material-ui/core/Slider";

var formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

export default function Questionarre(formData, setFormData) {
	const updateData = (field, value) => {
		const data = formData;
		data.setFormData({ ...data.formData, [field]: value });
	};

	const {
		householdMonthlyGross,
		amiCalculator,
		expSuddenLossIncome,
		livingWithoutLease,
		expHomelessness,
		everHadNameOnLease,
		error,
		whatCity,
		evictionNotice,
		evictionNoticeDate,
		policeInvolvement,
		householdMajorChange,
		anyoneInHouseholdDisabled,
		dischargeHospitalMHSub,
		ineligibleAssistance,
		unemployed90Days,
		homelessLast2Years,
		landlordFiledPapers,
		landlordHarrassment,
		householdPriorMonthlyGross,
		howManyInHousehold,
		specificOrganization,
		beingPressured,
		pastDueRentNotice,
		receivedCourtPapers,
		expHomelessLast2,
		firstTimeHomeless,
	} = formData.formData;
	// console.log("questionare data", formData);

	const limits2019 = [130500, 149200, 167800, 186400, 201400, 216300, 231200, 246100];
	const limits502020 = [45700, 52200, 58750, 65250, 70500, 75700, 80950, 86150];
	const emyLimits2019 = [78200, 83950, 100550, 111700, 120650];

	const limits2020 = [];

	const houseHoldQty =
		howManyInHousehold && howManyInHousehold != ""
			? howManyInHousehold == "9+"
				? 8
				: parseInt(howManyInHousehold)
			: 0;

	if (householdMonthlyGross)
		console.log(
			"householdQty is",
			houseHoldQty,
			limits2019[parseInt(houseHoldQty) - 1],
			householdMonthlyGross * 12,
			limits2019[parseInt(houseHoldQty) - 1]
		);

	if (whatCity && whatCity == "Emeryville" && amiCalculator > emyLimits2019.length)
		setTimeout(() => {
			updateData("amiCalculator", 1);
		}, 200);

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
				These questions are used to better understand your current housing needs
			</Typography>

			<Grid container spacing={3}>
				{/* #1 */}
				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"} required>
						<InputLabel shrink htmlFor="beingPressured">
							Are you being pressured to move out of your housing by your landlord, master tenant,
							apartment manager, roommate or anyone else?
						</InputLabel>
						<InputLabel htmlFor="beingPressured">
							Are you being pressured to move out of your housing by your landlord, master tenant,
							apartment manager, roommate or anyone else?
						</InputLabel>
						<Select
							native
							required
							error={error && (!beingPressured || beingPressured == "")}
							value={beingPressured}
							onChange={(evt) => updateData("beingPressured", evt.target.value)}
							inputProps={{
								name: "beingPressured",
								id: "beingPressured",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"} required>
						<InputLabel shrink htmlFor="pastDueRentNotice">
							Has your landlord given you something in writing (a notice) that you have past-due
							rent?
						</InputLabel>
						<InputLabel htmlFor="pastDueRentNotice">
							Has your landlord given you something in writing (a notice) that you have past-due
							rent?
						</InputLabel>
						<Select
							native
							required
							error={error && (!pastDueRentNotice || pastDueRentNotice == "")}
							value={pastDueRentNotice}
							onChange={(evt) => updateData("pastDueRentNotice", evt.target.value)}
							inputProps={{
								name: "pastDueRentNotice",
								id: "pastDueRentNotice",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"} required>
						{/* <InputLabel shrink htmlFor="receivedCourtPapers">
							Have you received court papers (an ‘Unlawful Detainer’) to move out of your housing?
							If yes, please contact the Eviction Defense Collaborative immediately at (415)
							659-9184 or legal@evictiondefense.org and continue with this application.
						</InputLabel> */}
						<InputLabel htmlFor="receivedCourtPapers" style={{ marginTop: -30, textAlign: "left" }}>
							Have you been served with eviction court papers to move out of your housing (
							<a
								href={`https://url.avanan.click/v2/___https:/www.courts.ca.gov/documents/sum130.pdf___.YXAzOnNmZHQyOmE6bzo4ZjM5YjVkMmZiYTM2NWEyNDA4YTI2YjNiNmM1MWFmYzo2OjVlODU6MWQ2NzY1Mjc2MTlhNDAxZTY0Mjg0Y2M0OTgyMDRkNTY3YTVhMGVhNzMwYzllZThlMzg3YjgyNWE4M2NkYzFlMDpoOlQ`}
								target="_blank"
							>
								click here for an example
							</a>
							)? These documents are different than a 3-day notice for non-payment of rent and will
							have the words ‘Unlawful Detainer,’ ‘Summons,’ or “Complaint” written on them. If yes,
							please contact the Eviction Defense Collaborative immediately at (415) 659-9184 or{" "}
							<a href={`mailto:legal@evictiondefense.org`}>legal@evictiondefense.org</a> and
							continue with this application.
						</InputLabel>
						<Select
							native
							required
							error={error && (!receivedCourtPapers || receivedCourtPapers == "")}
							value={receivedCourtPapers}
							onChange={(evt) => updateData("receivedCourtPapers", evt.target.value)}
							inputProps={{
								name: "receivedCourtPapers",
								id: "receivedCourtPapers",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #2 */}
				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal longText"}>
						<InputLabel shrink htmlFor="livingWithoutLease">
							Are you currently living in someone else’s home or apartment where you do not have a
							lease?
						</InputLabel>
						<InputLabel htmlFor="livingWithoutLease">
							Are you currently living in someone else’s home or apartment where you do not have a
							lease?
						</InputLabel>
						<Select
							required
							error={error && (!livingWithoutLease || livingWithoutLease == "")}
							native
							value={livingWithoutLease}
							onChange={(evt) => updateData("livingWithoutLease", evt.target.value)}
							inputProps={{
								name: "livingWithoutLease",
								id: "livingWithoutLease",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #3 */}

				{/* #5
				<Grid item xs={12} className={"longText"}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
						{householdMonthlyGross && householdMonthlyGross != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="householdMonthlyGross">
								<span>
									What is your HOUSEHOLD'S current <b>monthly</b> income?
								</span>
							</InputLabel>
						)}
						<TextField
							required
							error={error && (!householdMonthlyGross || householdMonthlyGross == "")}
							id="householdMonthlyGross"
							name="householdMonthlyGross"
							label={
								<span>
									What is your HOUSEHOLD'S current <b>monthly</b> income?
								</span>
							}
							type={"number"}
							fullWidth
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							defaultValue={householdMonthlyGross}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" style={{ color: "#666" }}>
							Your current annual income is: ${householdMonthlyGross * 12}
						</Typography>
					</Grid>
				)}
				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
							You are{" "}
							{parseFloat(
								((householdMonthlyGross * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
							).toFixed(2)}{" "}
							percent of the area median income
						</Typography>
					</Grid>
				)} */}

				{/* <Grid item xs={12} className={"longText"}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
						{householdPriorMonthlyGross && householdPriorMonthlyGross != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="householdPriorMonthlyGross">
								What was your HOUSEHOLD'S <b>monthly</b> income prior to the COVID-19 Pandemic
								(March 2020)?
							</InputLabel>
						)}
						<TextField
							required
							error={error && (!householdPriorMonthlyGross || householdPriorMonthlyGross == "")}
							id="householdPriorMonthlyGross"
							name="householdPriorMonthlyGross"
							label={
								<span>
									What was your HOUSEHOLD'S <b>monthly</b> income prior to the COVID-19 Pandemic
									(March 2020)?
								</span>
							}
							type={"number"}
							fullWidth
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							defaultValue={householdMonthlyGross}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid> */}

				{/* {householdPriorMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" style={{ color: "#666" }}>
							Your previous annual income was: ${householdPriorMonthlyGross * 12}
						</Typography>
					</Grid>
				)}
				{householdPriorMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
							You were{" "}
							{parseFloat(
								((householdPriorMonthlyGross * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
							).toFixed(2)}{" "}
							percent of the area median income
						</Typography>
					</Grid>
				)} */}

				{/* <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            <Typography variant="h6" gutterBottom>
              {whatCity == "Emeryville"
                ? "Emeryville 2019 100% AMI Income Limit Calculator"
                : "2020 50% of AMI Calculator"}
            </Typography>
            <Slider
              style={{ paddingLeft: 5, paddingRight: 5 }}
              defaultValue={1}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                whatCity == "Emeryville"
                  ? emyLimits2019.length
                  : limits502020.length
              }
              value={amiCalculator2}
              onChange={(evt, val) => updateData("amiCalculator2", val)}
              marks={
                whatCity == "Emeryville"
                  ? emyLimits2019.map((limit, i) => {
                      // console.log("mapping emyLimits", limit, i);
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
                  : limits502020.map((limit, i) => {
                      // console.log("mapping normal limits");
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
              }
            />
          </Grid>


          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: -10,
              paddingTop: 0,
              textDecoration: "bold",
            }}
          >
            <div style={{ fontWeight: 600, width: "100%" }}>
              {amiCalculator2 && amiCalculator2 > 0 ? (
                <div>
                  {amiCalculator2}{" "}
                  {amiCalculator2 <= 1 ? " person 50% limit: " : " people 50% limit: "}
                  {formatter
                    .format(
                      whatCity == "Emeryville"
                        ? emyLimits2019[amiCalculator2 - 1]
                        : limits502020[amiCalculator2 - 1]
                    )
                    .toString()
                    .replace(".00", "")}
                </div>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!makeLess50AMI || makeLess50AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="makeLess50AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 50% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="makeLess50AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 50% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess50AMI}
                onChange={(evt) =>
                  updateData("makeLess50AMI", evt.target.value)
                }
                inputProps={{
                  name: "makeLess50AMI",
                  id: "makeLess50AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid>


        {whatCity != "Hayward" && (
          <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            <Typography variant="h6" gutterBottom>
              {whatCity == "Emeryville"
                ? "Emeryville 2019 100% AMI Income Limit Calculator"
                : "2020 30% of AMI Calculator"}
            </Typography>
            <Slider
              style={{ paddingLeft: 5, paddingRight: 5 }}
              defaultValue={1}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                whatCity == "Emeryville"
                  ? emyLimits2019.length
                  : limits2019.length
              }
              value={amiCalculator}
              onChange={(evt, val) => updateData("amiCalculator", val)}
              marks={
                whatCity == "Emeryville"
                  ? emyLimits2019.map((limit, i) => {
                      // console.log("mapping emyLimits", limit, i);
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
                  : limits2019.map((limit, i) => {
                      // console.log("mapping normal limits");
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
              }
            />
          </Grid>
        )}
        {whatCity != "Hayward" && (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: -10,
              paddingTop: 0,
              textDecoration: "bold",
            }}
          >
            <div style={{ fontWeight: 600, width: "100%" }}>
              {amiCalculator && amiCalculator > 0 ? (
                <div>
                  {amiCalculator}{" "}
                  {amiCalculator <= 1 ? " person 30% limit: " : " people 30% limit: "}
                  {formatter
                    .format(
                      whatCity == "Emeryville"
                        ? emyLimits2019[amiCalculator - 1]
                        : limits2019[amiCalculator - 1]
                    )
                    .toString()
                    .replace(".00", "")}
                </div>
              ) : null}
            </div>
          </Grid>
        )}
        {whatCity != "Hayward" && (

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!makeLess30AMI || makeLess30AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 30% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                    <span>Does your household’s total income equal less than 30% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess30AMI}
                onChange={(evt) =>
                  updateData("makeLess30AMI", evt.target.value)
                }
                inputProps={{
                  name: "makeLess30AMI",
                  id: "makeLess30AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid>
        )}

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!priorToCovid30AMI || priorToCovid30AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="priorToCovid30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Prior to the COVID-19 pandemic, did your household’s total income equal less than 30% AMI based on the amounts above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="priorToCovid30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                    <span>Prior to the COVID-19 pandemic, did your household’s total income equal less than 30% AMI based on the amounts above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess30AMI}
                onChange={(evt) =>
                  updateData("priorToCovid30AMI", evt.target.value)
                }
                inputProps={{
                  name: "priorToCovid30AMI",
                  id: "priorToCovid30AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid> */}

				{/* #11 */}
				<Grid item xs={12}>
					<FormControl fullWidth required className={"MuiFormControl-marginNormal longText"}>
						<InputLabel shrink htmlFor="expHomelessness">
							Are you currently or have you ever been homeless (for example, you slept in a shelter,
							outside, in your car, or another place not meant for people to sleep)?
						</InputLabel>
						<InputLabel htmlFor="expHomelessness">
							Are you currently or have you ever been homeless (for example, you slept in a shelter,
							outside, in your car, or another place not meant for people to sleep)?
						</InputLabel>
						<Select
							required
							error={error && (!expHomelessness || expHomelessness == "")}
							native
							value={expHomelessness}
							onChange={(evt) => updateData("expHomelessness", evt.target.value)}
							inputProps={{
								name: "expHomelessness",
								id: "expHomelessness",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{expHomelessness && expHomelessness == "Yes" && (
					<Grid item xs={12} sm={12}>
						<FormControl
							required
							fullWidth
							className={"MuiFormControl-marginNormal longText"}
							required
							error={error && (!homelessLast2Years || homelessLast2Years == "")}
						>
							<InputLabel shrink htmlFor="homelessLast2Years">
								Did your experience of homelessness occur in the last two years?
							</InputLabel>
							<InputLabel htmlFor="homelessLast2Years">
								Did your experience of homelessness occur in the last two years?
							</InputLabel>
							<Select
								native
								required
								value={homelessLast2Years}
								onChange={(evt) => updateData("homelessLast2Years", evt.target.value)}
								inputProps={{
									name: "homelessLast2Years",
									id: "homelessLast2Years",
								}}
							>
								<option aria-label="None" value="" p={0} />
								<option value={"Yes"}>Yes</option>
								<option value={"No"}>No</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{expHomelessness && expHomelessness == "Yes" && (
					<Grid item xs={12} sm={12}>
						<FormControl
							required
							fullWidth
							className={"MuiFormControl-marginNormal longText"}
							required
							error={error && (!firstTimeHomeless || firstTimeHomeless == "")}
						>
							<InputLabel shrink htmlFor="firstTimeHomeless">
								Was this your first time experiencing homelessness?
							</InputLabel>
							<InputLabel htmlFor="firstTimeHomeless">
								Was this your first time experiencing homelessness?
							</InputLabel>
							<Select
								native
								required
								value={firstTimeHomeless}
								onChange={(evt) => updateData("firstTimeHomeless", evt.target.value)}
								inputProps={{
									name: "firstTimeHomeless",
									id: "firstTimeHomeless",
								}}
							>
								<option aria-label="None" value="" p={0} />
								<option value={"Yes"}>Yes</option>
								<option value={"No"}>No</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						required
						error={error && (!landlordFiledPapers || landlordFiledPapers == "")}
					>
						<InputLabel shrink htmlFor="landlordFiledPapers">
							As an adult, have you lost your home through eviction?
						</InputLabel>
						<InputLabel htmlFor="landlordFiledPapers">
							As an adult, have you lost your home through eviction?
						</InputLabel>
						<Select
							native
							required
							value={landlordFiledPapers}
							onChange={(evt) => updateData("landlordFiledPapers", evt.target.value)}
							inputProps={{
								name: "landlordFiledPapers",
								id: "landlordFiledPapers",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
					The following questions will not in any way affect your eligibility for assistance. We are
					asking them to determine if you may be eligible for additional services.
					<br />
					We believe everyone has the right to safe and stable housing.
				</Typography>

				{/* #13 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!policeInvolvement || policeInvolvement == "")}
					>
						<InputLabel shrink htmlFor="policeInvolvement">
							Have you or any adult in your household been arrested or spent any time in jail or
							prison in the last two years?
						</InputLabel>
						<InputLabel htmlFor="policeInvolvement">
							Have you or any adult in your household been arrested or spent any time in jail or
							prison in the last two years?
						</InputLabel>
						<Select
							native
							required
							value={policeInvolvement}
							onChange={(evt) => updateData("policeInvolvement", evt.target.value)}
							inputProps={{
								name: "policeInvolvement",
								id: "policeInvolvement",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #14 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!anyoneInHouseholdDisabled || anyoneInHouseholdDisabled == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="household-disabled">
							Do you or anyone in your household have a disabling condition which impacts your
							ability to secure/maintain housing?
						</InputLabel>
						<InputLabel htmlFor="anyoneInHouseholdDisabled">
							Do you or anyone in your household have a disabling condition which impacts your
							ability to secure/maintain housing?
						</InputLabel>
						<Select
							native
							value={anyoneInHouseholdDisabled}
							onChange={(evt) => updateData("anyoneInHouseholdDisabled", evt.target.value)}
							inputProps={{
								name: "anyoneInHouseholdDisabled",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #15 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!dischargeHospitalMHSub || dischargeHospitalMHSub == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="dischargeHospitalMHSub">
							Have you or any adult in your household been discharged from a hospital, mental health
							facility or substance abuse treatment facility in the last year?
						</InputLabel>
						<InputLabel htmlFor="dischargeHospitalMHSub">
							Have you or any adult in your household been discharged from a hospital, mental health
							facility or substance abuse treatment facility in the last year?
						</InputLabel>
						<Select
							native
							value={dischargeHospitalMHSub}
							onChange={(evt) => updateData("dischargeHospitalMHSub", evt.target.value)}
							inputProps={{
								name: "dischargeHospitalMHSub",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #15 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!ineligibleAssistance || ineligibleAssistance == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="ineligibleAssistance">
							Do you or anyone in your household have or need an{" "}
							<a href="https://www.nilc.org/issues/taxes/itinfaq" target="_blank">
								ITIN (Individual Taxpayer Identification Number)?
							</a>
						</InputLabel>
						<InputLabel htmlFor="ineligibleAssistance">
							Do you or anyone in your household have or need an{" "}
							<a href="https://www.nilc.org/issues/taxes/itinfaq" target="_blank">
								ITIN (Individual Taxpayer Identification Number)?
							</a>
						</InputLabel>
						<Select
							native
							value={ineligibleAssistance}
							onChange={(evt) => updateData("ineligibleAssistance", evt.target.value)}
							inputProps={{
								name: "ineligibleAssistance",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!specificOrganization || specificOrganization == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						{/* <InputLabel shrink htmlFor="specificOrganization">
							If you prefer to work with a specific organization to determine whether you qualify
							for assistance, please select one from the list below. Learn more about these
							organizations{" "}
							<a href="/attachments/Guide to Choosing an SF ERAP Provider.pdf">here</a>.
						</InputLabel> */}
						<InputLabel htmlFor="specificOrganization" style={{ marginTop: -40 }}>
							If you prefer to work with a specific organization to determine whether you qualify
							for assistance, please select one from the list below. Learn more about these
							organizations{" "}
							<a href="/attachments/Guide to Choosing an SF ERAP Provider.pdf" target="_blank">
								here
							</a>
							.
						</InputLabel>
						<Select
							native
							value={specificOrganization}
							onChange={(evt) => updateData("specificOrganization", evt.target.value)}
							inputProps={{
								name: "specificOrganization",
								id: "specificOrganization",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Catholic Charities of San Francisco"}>
								Catholic Charities of San Francisco (CCSF)
							</option>
							<option value={"Compass Family Services"}>
								Compass Family Services (only serves households with custody of children)
							</option>
							{/* <option value={"EDC-RADCo"}>Eviction Defense Collaborative (EDC-RADCo)</option> */}
							<option value={"Hamilton Families"}>
								Hamilton Families (only serves households with custody of children)
							</option>
							<option value={"Homeless Prenatal Program"}>
								Homeless Prenatal Program (HPP - only serves households with custody of children)
							</option>
							<option value={"Homies Organizing the Mission to Empower Youth (HOMEY)"}>
								Homies Organizing the Mission to Empower Youth (HOMEY)
							</option>
							<option value={"La Raza Community Resource Center"}>
								La Raza Community Resource Center
							</option>
							<option value={"Mission Neighborhood Centers"}>
								Mission Neighborhood Centers (MNC)
							</option>
							<option value={"Native American Health Center"}>
								Native American Health Center (NAHC)
							</option>
							{/* <option value={"Young Community Developers"}>Young Community Developers (YCD)</option> */}
							<option value={"No Preference"}>No Preference</option>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
