const Languages = [
	"Spanish",
	"Cantonese",
	"Mandarin",
	"Filipino",
	"Russian",
	"Ukrainian",
	"Arabic",
	"Vietnamese",
	"Portuguese",
	"Korean",
	"American Sign Language",
	"Other",
];

module.exports = Languages;
