import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DescriptionIcon from "@material-ui/icons/Description";
import CircleIcon from "@material-ui/icons/PlayCircleFilled";
import Dot from "@material-ui/icons/FiberManualRecord";
// import Dot from "@material-ui/icons/Brightness1Outlined";

import SF from "./images/sf.png";
import SFMayor from "./images/sfmayor.jpg";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

const imgStyle = {
	height: 150,
	// maxWidth: "80%",
	objectFit: "contain",
	display: "block",
	margin: "0 auto",
	marginTop: 10,
	marginBottom: 10,
	maxWidth: "100%",
};

var formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

const ami50 = [102450, 117100, 131750, 146350, 158100, 169800, 181500, 193200];

const ami30 = [102450, 117100, 131750, 146350, 158100, 169800, 181500, 193200];

const amiTable = [5713, 6529, 7346, 8158, 8813, 9467, 10117, 10771];

export default function SelectCity(data) {
	const [open, setOpen] = React.useState(false);

	const [renterLandlord, setRenterLandlord] = React.useState({
		type: "",
		income50ami: false,
		deedRestricted: false,
		buttonClicked: false,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const updateData = (field, value) => {
		data.setFormData({ ...data.formData, ready: true });
	};

	const hCutoff =
		new Date().toUTCString() >= new Date(Date.UTC(2020, 4, 20, 1, 0, 0)).toUTCString();

	console.log("data", data);

	const { whatCity, error, bypassRequired } = data.formData;

	const { setActiveStep } = data;

	return (
		<React.Fragment>
			<Dialog
				open={open && whatCity == "San Francisco"}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onBackdropClick={async () => {
					setRenterLandlord({
						type: "",
						income50ami: false,
						deedRestricted: false,
						buttonClicked: false,
					});
					handleClose();
				}}
			>
				{/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
				<DialogContent>
					<DialogContentText id="alert-dialog-description"></DialogContentText>

					{renterLandlord.buttonClicked && renterLandlord.type == "landlord" && (
						<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
							<Grid item xs={12}>
								{renterLandlord.deedRestricted && (
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes properties that
										are 100% restricted affordable housing.
										<br />
										<br /> To apply, please email:{" "}
										<a
											target="_blank"
											href={
												"mailto:HousingAssistance@oaklandca.gov?subject=ERAP for Affordable Housing"
											}
										>
											HousingAssistance@oaklandca.gov
										</a>{" "}
										to request a Property Profile Application Form. Please note: "ERAP for
										Affordable Housing" in subject line. This Application Form will include
										instructions.​
									</div>
								)}
								{!renterLandlord.deedRestricted && (
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes properties that
										are 100% restricted affordable housing. However, the State of CA is partnering
										with San Francisco to offer emergency rental assistance to landlords with
										properties in Oakland whose tenants earn 80% Area Median Income or less. <br />
										<br /> Please visit{" "}
										<a target="_blank" href={"http://housingiskey.com"}>
											www.housingiskey.com
										</a>
										, and click on CA COVID-19 Rent Relief for more information.
									</div>
								)}
							</Grid>
						</Grid>
					)}
					{!renterLandlord.buttonClicked && renterLandlord.type == "landlord" && (
						<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
							<Grid item xs={12}>
								<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
									The State of CA is partnering with San Francisco to offer emergency rental
									assistance to landlords with properties in San Francisco whose tenants earn 80%
									Area Median Income or less, including those that are restricted to 100% affordable
									housing.
									<br />
									<br />
									Please visit{" "}
									<a target="_blank" href={"http://housingiskey.com"}>
										www.housingiskey.com
									</a>
									, and click on CA COVID-19 Rent Relief for more information.
								</div>
							</Grid>
						</Grid>
					)}

					{renterLandlord.buttonClicked &&
						renterLandlord.type == "renter" &&
						!renterLandlord.income50ami && (
							<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
								<Grid item xs={12}>
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes households below
										30% of Area Median Income. However, the State of CA is partnering with Oakland
										to offer emergency rental assistance for households that earn between 30-80% of
										Area Median Income. <br />
										<br /> Please visit{" "}
										<a target="_blank" href="http://housingiskey.com">
											www.housingiskey.com
										</a>
										, and click on CA COVID-19 Rent Relief for more information. ​
									</div>
								</Grid>
							</Grid>
						)}
					{!renterLandlord.buttonClicked && renterLandlord.type == "renter" && (
						<Grid container spacing={1} style={{ paddingBottom: "2em" }} className="translate">
							<Grid item xs={12}>
								<div style={{ paddingBottom: "0.5em", textAlign: "center", fontSize: "1.5em" }}>
									Is your TOTAL CURRENT household income less than 30% Area Media Income?
								</div>
								<Grid container spacing={1} style={{ paddingBottom: 10 }}>
									<Grid item xs={5} style={{ textAlign: "right" }}>
										People in Household
									</Grid>
									<Grid item xs={2}></Grid>
									<Grid item xs={5}>
										30% of Median
									</Grid>
									{ami30.map((ami, i) => [
										<Grid key={`ami-${ami}-${i}-1`} item xs={5} style={{ textAlign: "right" }}>
											{i + 1}
										</Grid>,
										<Grid key={`ami-${ami}-${i}-2`} item xs={2}></Grid>,
										<Grid key={`ami-${ami}-${i}-3`} item xs={5}>
											{formatter.format(ami).replace(".00", "")}
										</Grid>,
									])}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
								<div
									aria-label="No"
									className="oaklandButton"
									onClick={() =>
										setRenterLandlord({
											...renterLandlord,
											buttonClicked: true,
											income50ami: false,
										})
									}
								>
									<strong>No</strong>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
								<div
									aria-label="Yes"
									className="oaklandButton"
									onClick={() => {
										updateData();
										handleClose();
									}}
								>
									<strong>Yes</strong>
								</div>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={async () => {
							// updateData("whatCity", "");
							setRenterLandlord({
								type: "",
								income50ami: false,
								deedRestricted: false,
								buttonClicked: false,
							});
							handleClose();
						}}
						color="primary"
					>
						Cancel
					</Button>
					{/* <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button> */}
				</DialogActions>
			</Dialog>

			<Grid container spacing={1}>
				<Grid item xs={12}>
					<img style={imgStyle} src={SF} />
				</Grid>
			</Grid>

			<Grid container spacing={1}>
				<Typography
					style={{
						color: "#666",
						marginTop: 50,
						width: "80%",
						marginLeft: "auto",
						marginRight: "auto",
						fontWeight: 600,
						textDecoration: "underline",
					}}
				>
					PROGRAM OVERVIEW
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12} style={{ cursor: "pointer" }}>
						<List style={{ paddingLeft: "10%", paddingRight: "10%" }}>
							<ListItem>
								{/* <ListItemIcon>
									<Dot />
								</ListItemIcon> */}
								<ListItemText
									primary="The San Francisco Emergency Rental Assistance Program (SF ERAP) provides assistance once per calendar year to eligible San Francisco residents who:"
									secondary={
										<List>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="Have past-due rent; " />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="Cannot afford move-in costs for a new unit they have identified and plan to lease; and/or " />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="Need limited assistance with future rent to maintain their housing. " />
											</ListItem>
										</List>
									}
								/>
							</ListItem>

							<ListItem>
								{/* <ListItemIcon>
									<Dot />
								</ListItemIcon> */}
								<ListItemText
									primary="Eligibility Criteria: "
									secondary={
										<List>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="Current resident of San Francisco " />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText
													primary={
														<span>
															Household income at or below 50% of the{" "}
															<a
																href={"https://www.huduser.gov/portal/datasets/il.html"}
																target="_blank"
															>
																Area Median Income
															</a>{" "}
															(AMI) for your household size:{" "}
														</span>
													}
													secondary={
														<center>
															<div style={{ textAlign: "center" }}>
																<table width={"80%"}>
																	<thead>
																		<th>Household Size</th>
																		{amiTable.map(function (val, ind) {
																			return <th>{ind + 1}</th>;
																		})}
																	</thead>
																	<tr>
																		<th>Monthly Income</th>
																		{amiTable.map(function (val, ind) {
																			return <th>{formatter.format(val).replace(".00", "")}</th>;
																		})}
																	</tr>
																</table>
															</div>
														</center>
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="If applying for back/future rent: Experienced a recent financial hardship within the past 12 months." />
											</ListItem>
											<ListItem>
												<ListItemIcon>
													<Dot style={{ fontSize: "1rem" }} />
												</ListItemIcon>
												<ListItemText primary="Permanent Supportive Housing (PSH), RAD, and HOPE SF tenants only: Please work with your property management and onsite services before applying to confirm your eligibility. Special processes apply for tenants in these programs." />
											</ListItem>
										</List>
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary={
										<span>
											Selection Criteria: Due to limited program funding, SF ERAP can only provide
											financial assistance to eligible applicants with the highest risk of
											homelessness or housing loss, based on responses to questions in this
											application. Learn more about the City’s selection criteria{" "}
											<a
												href={"https://sf.gov/information/sf-erap-program-rules"}
												target={"_blank"}
											>
												here
											</a>
											.
										</span>
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Meeting SF ERAP criteria, or receiving assistance from SF ERAP in the past, does not guarantee that you will receive assistance. We know that many households are struggling to make ends meet, but the program has limited resources and is not able to serve every household who applies.  "
									secondary={
										<span>
											If you do not qualify for financial assistance from SF ERAP, you may be
											eligible for other community resources listed{" "}
											<a
												href={"https://sf.gov/information/rent-relief-resources"}
												target={"_blank"}
											>
												here
											</a>
											.
										</span>
									}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									style={{ fontWeight: "bold" }}
									primary={
										<b>
											We are experiencing longer than usual wait times. It may take up to eight
											weeks or more to hear back about the status of your application so please be
											patient.
										</b>
									}
								/>
							</ListItem>
						</List>
					</Grid>
				</Grid>

				<Typography
					style={{
						color: "#666",
						textDecoration: "underline",
						marginTop: 50,
						width: "80%",
						marginLeft: "auto",
						marginRight: "auto",
						fontWeight: 600,
					}}
				>
					If you meet the criteria above, CLICK NEXT BELOW.
				</Typography>
				<Grid container spacing={3}>
					<Typography
						style={{
							color: "#666",
							marginTop: 50,
							width: "80%",
							marginLeft: "auto",
							marginRight: "auto",
							paddingBottom: 25,
							fontWeight: 600,
						}}
					>
						You may be protected from eviction under San Francisco’s Rent Ordinance and other
						applicable laws. For more information about eviction protections in San Francisco,
						contact the Rent Board at 415-252-4600. There are also free mediation, tenant
						counseling, and legal assistance programs that may be helpful for your situation. Visit
						this{" "}
						<a href=" https://www.sf.gov/information/rent-relief-resources" target="_blank">
							list of resources
						</a>{" "}
						for more information. If you have received any eviction court papers telling you that
						you must leave your current home, please immediately contact the Eviction Defense
						Collaborative at 415-659-9184 or 
						<a href="mailto:legal@evictiondefense.org">legal@evictiondefense.org</a>.
					</Typography>
					{/* <Typography
						style={{
							color: "#666",
							textDecoration: "underline",
							marginTop: 25,
							marginBottom: 25,
							width: "80%",
							marginLeft: "auto",
							marginRight: "auto",
							fontWeight: 600,
						}}
					>
						If none of these apply to you, please{" "}
						<a target="_blank" href="https://sf.gov/information/rent-relief-resources">
							CLICK HERE for other services.
						</a>
					</Typography> */}
				</Grid>

				<Grid item xs={12} style={{ position: "absolute", left: "-100000px" }}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="what-city">
							What city do you live in?
						</InputLabel>
						<InputLabel htmlFor="what-city">What city do you live in?</InputLabel>
						<Select
							native
							value={whatCity}
							onChange={(evt) => {
								console.log(evt.target);
								if (evt.target.value !== "Oakland") setOpen(true);
								if (
									evt.target.value == "Hayward2" ||
									(evt.target.value == "Emeryville" && new Date() >= new Date("06/16/2020"))
								) {
									// updateData("whatCity", null);
									updateData("whatCity", evt.target.value);
									document.getElementById(
										"message"
									).textContent = `Our apologies but the application portal has been closed due to the rent relief funding having been fully expended.`;
								} else {
									document.getElementById("message").textContent = "";
									updateData("whatCity", evt.target.value);
									if (evt.target.value == "Oakland") document.location.href = "https://sferap.com/";
									if (evt.target.value == "Fremont") document.location.href = "https://sferap.com/";
								}
							}}
							inputProps={{
								name: "whatCity",
								id: "what-city",
							}}
						>
							<option aria-label="None" value={""} />
							{/* <option value={"Emeryville"}>Emeryville</option> */}
						</Select>
					</FormControl>
				</Grid>
				{/* <Grid item xs={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!whatCity || whatCity == "")}
          >
            <InputLabel shrink htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <InputLabel htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <Select
              native
              value={whatCity}
              onChange={(evt) => {
                console.log(evt.target);
                if (evt.target.value !== "Oakland") setOpen(true);
                if (
                  evt.target.value == "Hayward2" ||
                  (evt.target.value == "Emeryville" &&
                    new Date() >= new Date("06/16/2020"))
                ) {
                  // updateData("whatCity", null);
                  updateData("whatCity", evt.target.value);
                  document.getElementById(
                    "message"
                  ).textContent = `Our apologies but the Emeryville application portal has been closed due to the rent relief funding having been fully expended.`;
                } else {
                  if (document.getElementById("message"))
                  document.getElementById("message").textContent = "";
                  updateData("whatCity", evt.target.value);
                }
              }}
              inputProps={{
                name: "whatCity",
                id: "what-city",
              }}
            >
              <option aria-label="None" value={""} />
              <option value={"Fremont"}>Fremont</option>
              <option value={"Oakland"}>Oakland</option>
              <option value={"San Francisco"}>San Francisco</option>
            </Select>
          </FormControl>
        </Grid> */}
				{/* <Typography
        style={{ color: "#666", textDecoration: "", marginTop: 1, paddingBottom: '3em', textAlign: 'center', width: '100%' }}
      >
      <h3>This site is currently under construction.</h3><br/>
Please check back on 5/28/2021.
        You will receive an email confirmation of your submission.  Please do not submit more than 1 application per household.  Submitting more than 1 application could slow down your application processing.
        </Typography> */}
			</Grid>
		</React.Fragment>
	);
}
