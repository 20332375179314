import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";

import SFDocs from "./SFDocs.pdf";

const imgStyle = {
	height: 65,
	// maxWidth: "80%",
	objectFit: "cover",
	display: "block",
	margin: "0 auto",
	marginTop: 10,
	marginBottom: 10,
};

export default function Intro(formData) {
	const { whatCity } = formData.formData;

	return (
		<React.Fragment>
			{/* <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Emeryville} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Hayward} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
      </Grid> */}
			{/* <div>
        <img style={imgStyle} src={cityImage()} />
      </div> */}
			<Typography style={{ color: "#666", textDecoration: "underline", marginTop: 1 }}>
				To receive assistance, you will need to provide the following documents:
			</Typography>
			<Grid
				container
				spacing={3}
				onClick={() => window.open("/attachments/SFDocExamples.pdf", "_blank")}
			>
				<Grid item xs={12} style={{ cursor: "pointer" }}>
					<List>
						<ListItem>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary="One form of personal identification​" />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary="Income verification for all household members over 18 with income​" />
						</ListItem>

						<ListItem>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary="Proof of San Francisco residency" />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary="Proof of unpaid rent or move-in costs  owed" />
						</ListItem>

						<ListItem>
							<ListItemIcon>
								<DescriptionIcon />
							</ListItemIcon>
							<ListItemText primary="Verification of your financial hardship within the last 12 months (if applying for back rent assistance)" />
						</ListItem>
					</List>

					<ListItem>
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText primary="Verification of your housing subsidy (if applicable)" />
					</ListItem>

					<ListItem>
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText primary="Your eviction court papers (if applicable)" />
					</ListItem>
				</Grid>
			</Grid>

			<Typography gutterBottom style={{ color: "#666", textAlign: "left" }}>
				Please click any of the items above to see examples of the kinds of documents that will be
				accepted. If you have trouble providing documents, a case manager will work with you to
				verify your information. You may also be asked for additional documents not listed here to
				confirm any of the information provided in your application.
				<br />
				<br />
				You will <span style={{ textDecoration: "underline", fontWeight: 600 }}>not</span> be asked
				about citizenship or immigration status, nor will you be required to show proof of
				citizenship.
			</Typography>
			{/* <Typography gutterBottom style={{ color: "#666", textAlign: "left" }}>
				<br />
				You are not guaranteed to receive financial assistance through this program. Due to limited
				resources, only applicants at highest risk of homelessness or housing loss will advance to
				the next stage of the process. If you advance to the next stage of the process, you will
				need to submit these documents for verification purposes. If you have trouble providing
				documents, a case worker will work with you to verify your information.
			</Typography> */}
			<Typography
				variant="h6"
				gutterBottom
				style={{ color: "#666", paddingTop: 25, textAlign: "left" }}
			>
				Need Help?
			</Typography>
			<Typography gutterBottom style={{ color: "#666", paddingTop: 5, textAlign: "left" }}>
				Contact a community partner listed{" "}
				<a href="https://sf.gov/information/rent-relief-resources" target="_blank">
					here
				</a>{" "}
				for help with your application.
			</Typography>
			<Typography
				variant="h5"
				gutterBottom
				style={{ color: "#666", paddingTop: 20, textAlign: "center" }}
			>
				Please expect to spend at least 15 minutes completing this application and be sure to click
				submit, as the form cannot save your changes.
			</Typography>
			{/* <Typography
        variant="h6"
        gutterBottom
        style={{ color: "#666", paddingTop: 50 }}
      >
        If you have gathered the necessary documents, click the NEXT button to begin.
      </Typography> */}
		</React.Fragment>
	);
}
